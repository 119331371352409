import React, { useState } from 'react';
import styled from 'styled-components';
import Header from '../components/Header';
import ImageComponent from '../components/ImageComponent';
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100vw;
  height: calc(100vh - 60px);
  background-color: #f0f0f0;
  padding: 20px 15px;
  box-sizing: border-box;
  overflow-x: auto;
`;

const TableWrapper = styled.div`
  width: 100%;
  overflow-x: auto;
`;

const Table = styled.table`
  width: 100%;
  min-width: 1600px; /* Increase min-width for additional columns */
  border-collapse: collapse;
  margin-top: 20px;
`;

const Th = styled.th`
  padding: 10px;
  background-color: #007bff;
  color: white;
  text-align: center;
  font-size: 14px;
  word-break: break-word;
  min-width: 150px;
`;

const Td = styled.td`
  padding: 10px;
  border: 1px solid #ddd;
  font-size: 14px;
  text-align: center;
  word-break: break-word;
  min-width: 150px;
  max-width: 250px;
  white-space: pre-wrap;
`;

const Input = styled.input`
  width: 100%;
  border: none;
  background: transparent;
  font-size: 14px;
  padding: 5px;
  text-align: center;
`;

const Checkbox = styled.input.attrs({ type: 'checkbox' })`
  cursor: pointer;
  width: 20px;
  height: 20px;
`;

const Button = styled.button`
  padding: 5px 10px;
  font-size: 14px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  background-color: ${(props) => (props.color ? props.color : '#007bff')};
  color: white;

  &:hover {
    background-color: ${(props) => (props.color === 'gray' ? '#bbb' : '#0056b3')};
  }
`;


const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
`;

const Modal = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ModalTitle = styled.h2`
  margin-bottom: 20px;
  text-align: center;
`;

const ButtonRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const ModalButton = styled(Button)<{ bold?: boolean }>`
  font-weight: ${(props) => (props.bold ? 'bold' : 'normal')};
  width: 45%;
`;

const Tr = styled.tr<{ isPaid?: boolean }>`
  &:nth-child(even) {
    background-color: ${props => props.isPaid ? '#d1d1d1' : '#f2f2f2'};
  }
  &:nth-child(odd) {
    background-color: ${props => props.isPaid ? '#66D05C' : '#ffffff'};
  }
  transition: background-color 0.3s ease;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
  padding: 20px;
  width: 100%;
  position: sticky;
  bottom: 0;
  background-color: #f0f0f0;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
`;

const ActionButton = styled.button`
  padding: 12px 30px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  border: none;
  border-radius: 8px;
  color: white;
  transition: all 0.3s ease;
  min-width: 150px;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  &:active {
    transform: translateY(0);
  }
`;

const AddButton = styled(ActionButton)`
  background-color: #007bff;
  &:hover {
    background-color: #0056b3;
  }
`;

const ExportButton = styled(ActionButton)`
  background-color: #28a745;
  &:hover {
    background-color: #218838;
  }
`;

const TrashIcon = styled.span`
  font-size: 16px;
  display: inline-block;
  cursor: pointer;
  color: white;
`;

interface DataRow {
  numero: string;
  apellidos: string;
  nombres: string;
  cobertura: string;
  monto: string;
  placas: string;
  serie: string;
  origen: string;
  marca: string;
  linea: string;
  version: string;
  modelo: string;
  domicilio: string;
  colonia: string;
  codigoPostal: string;
  telefono: string;
  correo: string;
  rfc: string;
  numeroPoliza: string;
  polizaArchivo: string;
  pagado: boolean;
  INE_front: string;
  INE_back: string;
  TarjetaCirculacion: string;
}

interface TablaFilaProps {
  data: DataRow[];
}

// Add a type for different modal types
type ModalType = 'generate' | 'delete' | null;

const TablaFila: React.FC<TablaFilaProps> = ({ data }) => {
  const [editedData, setEditedData] = useState(data);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalType, setModalType] = useState<ModalType>(null);
  const [selectedRow, setSelectedRow] = useState<{ data: DataRow; index: number } | null>(null);

  const createEmptyRow = (): DataRow => {
    const lastRow = editedData[editedData.length - 1];
    const nextNumber = lastRow 
      ? String(Number(lastRow.numero) + 1)
      : "1";

    return {
      numero: nextNumber,
      apellidos: "",
      nombres: "",
      cobertura: "",
      monto: "",
      placas: "",
      serie: "",
      origen: "",
      marca: "",
      linea: "",
      version: "",
      modelo: "",
      domicilio: "",
      colonia: "",
      codigoPostal: "",
      telefono: "",
      correo: "",
      rfc: "",
      numeroPoliza: "",
      polizaArchivo: "",
      pagado: false,
      INE_front: "",
      INE_back: "",
      TarjetaCirculacion: ""
    };
  };

  const handleEdit = (index: number, key: keyof DataRow, value: string | boolean) => {
    const newData = [...editedData];
    newData[index][key] = value as never;
    setEditedData(newData);
  };

  const handleCheckboxChange = (index: number) => {
    const newData = [...editedData];
    newData[index].pagado = !newData[index].pagado;
    setEditedData(newData);
  };

  const handleGenerarClick = (row: DataRow) => {
    setSelectedRow({ data: row, index: editedData.indexOf(row) });
    setModalType('generate');
    setModalOpen(true);
  };

  const handleDeleteClick = (row: DataRow, index: number) => {
    setSelectedRow({ data: row, index });
    setModalType('delete');
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setModalType(null);
    setSelectedRow(null);
  };

  const handleConfirmGenerate = () => {
    console.log('Generar póliza for:', selectedRow?.data);
    handleCloseModal();
  };

  const handleConfirmDelete = () => {
    if (selectedRow !== null) {
      const newData = editedData.filter((_, index) => index !== selectedRow.index);
      setEditedData(newData);
    }
    handleCloseModal();
  };

  const handleAdd = () => {
    const newRow = createEmptyRow();
    setEditedData([...editedData, newRow]);
  };

  const handleExport = () => {
    console.log('Export data');
  };

  const renderModal = () => {
    if (!modalOpen || !modalType) return null;

    return (
      <ModalOverlay>
        <Modal>
          <ModalTitle>
            {modalType === 'generate' ? '¿Generar póliza?' : '¿Borrar fila?'}
          </ModalTitle>
          <ButtonRow>
            <ModalButton color="gray" onClick={handleCloseModal}>
              Cancelar
            </ModalButton>
            <ModalButton 
              color="green" 
              bold 
              onClick={modalType === 'generate' ? handleConfirmGenerate : handleConfirmDelete}
            >
              {modalType === 'generate' ? 'Generar' : 'Confirmar'}
            </ModalButton>
          </ButtonRow>
        </Modal>
      </ModalOverlay>
    );
  };

  return (
    <>
      <Header title="Datos de Usuarios"  />
      <Wrapper>
        <TableWrapper>
          <Table>
            <thead>
              <tr>
                <Th>Numero</Th>
                <Th>Pagado</Th>
                <Th>Apellido(s)</Th>
                <Th>Nombre(s)</Th>
                <Th>Cobertura</Th>
                <Th>Monto</Th>
                <Th>Placas</Th>
                <Th>Serie</Th>
                <Th>Origen</Th>
                <Th>Marca</Th>
                <Th>Línea</Th>
                <Th>Versión</Th>
                <Th>Modelo</Th>
                <Th>Domicilio</Th>
                <Th>Colonia</Th>
                <Th>Código Postal</Th>
                <Th>Teléfono</Th>
                <Th>Correo</Th>
                <Th>RFC</Th>
                <Th>Numero Poliza</Th>
                <Th>Poliza Archivo</Th>
                <Th>INE (Frontal)</Th>
                <Th>INE (Trasera)</Th>
                <Th>Tarjeta Circulación</Th>
                <Th>Generar Póliza</Th>
                <Th>Eliminar</Th>
              </tr>
            </thead>
            <tbody>
              {editedData.map((row, index) => (
                <Tr key={index} isPaid={row.pagado}>
                  {Object.keys(row).map((key) => (
                    <Td key={key}>
                      {key === 'numero' ? (
                        // Non-editable numero field
                        <span>{row[key]}</span>
                      ) : key === 'INE_front' || key === 'INE_back' || key === 'TarjetaCirculacion' ? (
                        typeof row[key as keyof DataRow] === 'string' && (
                          <ImageComponent data={row[key as keyof DataRow] as string} />
                        )
                      ) : key === 'pagado' ? (
                        <Checkbox
                          checked={row[key as keyof DataRow] as boolean}
                          onChange={() => handleCheckboxChange(index)}
                        />
                      ) : (
                        <Input
                          value={row[key as keyof DataRow] as string}
                          onChange={(e) => handleEdit(index, key as keyof DataRow, e.target.value)}
                        />
                      )}
                    </Td>
                  ))}
                  <Td>
                    <Button onClick={() => handleGenerarClick(row)}>Generar</Button>
                  </Td>
                  <Td>
                    <Button 
                      onClick={() => handleDeleteClick(row, index)}
                      style={{ backgroundColor: '#dc3545', padding: '8px' }}
                    >
                      <TrashIcon>🗑️</TrashIcon>
                    </Button>
                  </Td>
                </Tr>
              ))}
            </tbody>
          </Table>
        </TableWrapper>

        <ButtonContainer>
          <AddButton onClick={handleAdd}>
            Agregar +
          </AddButton>
          <ExportButton onClick={handleExport}>
            Exportar
          </ExportButton>
        </ButtonContainer>

        {renderModal()}
      </Wrapper>
    </>
  );
};

export default TablaFila;