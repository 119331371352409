import React, { useState } from 'react';
import styled from 'styled-components';
import Header from '../components/Header'; // Import the Header component

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: calc(100vh - 60px); /* Adjust for header height */
  background-color: #f0f0f0;
  padding: 20px;
  box-sizing: border-box;
`;

const Title = styled.h1`
  font-size: 24px;
  margin-bottom: 20px;
  color: #333;
  text-align: center;
`;

const Label = styled.label`
  font-size: 18px;
  margin-top: 10px;
  margin-bottom: 5px;
  color: #333;
`;

const Input = styled.input`
  padding: 10px;
  font-size: 16px;
  margin-bottom: 20px;
  width: 80%;
  max-width: 400px;
  box-sizing: border-box;
`;

const Button = styled.button`
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  border: none;
  margin-top: 20px;

  &:hover {
    background-color: #0056b3;
  }
`;

interface InputInformationProps {
  onSubmit: (data: { telefono: string; correo: string; rfc: string }) => void;
}

const InputInformation: React.FC<InputInformationProps> = ({ onSubmit }) => {
  const [telefono, setTelefono] = useState('');
  const [correo, setCorreo] = useState('');
  const [rfc, setRfc] = useState('');

  const handleSubmit = () => {
    onSubmit({ telefono, correo, rfc });
  };

  return (
    <>
      {/* Add the Header component with the back button */}
      <Header title="Contacto"  />
      <Wrapper>
        <Title>Llena la siguiente información:</Title>
        <Label htmlFor="telefono">Telefono:</Label>
        <Input
          id="telefono"
          type="text"
          value={telefono}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setTelefono(e.target.value)}
          placeholder="Número de teléfono"
        />
        <Label htmlFor="correo">Correo:</Label>
        <Input
          id="correo"
          type="email"
          value={correo}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setCorreo(e.target.value)}
          placeholder="Correo electrónico"
        />
        <Label htmlFor="rfc">RFC:</Label>
        <Input
          id="rfc"
          type="text"
          value={rfc}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setRfc(e.target.value)}
          placeholder="RFC"
        />
        <Button onClick={handleSubmit}>Enviar</Button>
      </Wrapper>
    </>
  );
};

export default InputInformation;
