interface IneData {
  codigoPostal?: string;
  nombres?: string;
  apellidos?: string;
  domicilio?: string;
  colonia?: string;

}

async function removenewlines(text: string): Promise<string> {
  return text.replace(/\n/g, ' ');
}
async function removeAfterFirstNewline(text: string): Promise<string> {
  return text.split('\n')[0];
}
async function parseIneInfo(text: string): Promise<IneData> {
  let patterns = {   
    codigoPostal: /\d{5}/,                                 // Matches exactly one 5-digit number
    nombres: /(?<=\nNOMBRE\n(?:[A-Za-zÁÉÍÓÚáéíóúÑñ]+\n){2})([A-Za-zÁÉÍÓÚáéíóúÑñ\s]+)/, // Matches name(s) after two last names
    apellidos: /(?<=\nNOMBRE\n)([A-Za-zÁÉÍÓÚáéíóúÑñ]+\n[A-Za-zÁÉÍÓÚáéíóúÑñ]+)/,         // Matches exactly two lines for apellidos
    domicilio: /(?<=\nDOMICILIO\n)([^\n]+)/,                                           // Matches line right after "DOMICILIO"
    colonia: /(?<=\nDOMICILIO[^\n]*\n[^\n]*\n)([A-Za-zÁÉÍÓÚáéíóúÑñ\s]+)(?=\s?\d{5}|$)/, // Second line after domicilio without numbers
  
  };
  const result: IneData = {};
  for (const [key, pattern] of Object.entries(patterns)) {
    const match = text.match(pattern);
    result[key as keyof IneData] = match ? match[0] || "" : "";
  }
  if (result.apellidos) {
    result.apellidos = await removenewlines(result.apellidos);
  }
  if (result.nombres) {
    result.nombres = await removeAfterFirstNewline(result.nombres);
  }

  return result;
}

export default parseIneInfo;