import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { Camera, CameraType } from '../Camera';
import Header from '../components/Header'; // Import the Header component

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: calc(100vh - 60px); /* Adjust for header height */
  background-color: #f0f0f0;
  padding: 20px;
  box-sizing: border-box;
`;

const Title = styled.h1`
  font-size: 24px;
  margin-bottom: 20px;
  color: #333;
  text-align: center;
`;

const CameraWrapper = styled.div`
  width: 80%;
  max-width: 400px;
  height: 300px;
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border: 2px solid #ccc;
  margin-bottom: 20px;
`;

const TakePhotoButton = styled.button`
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  border: none;

  &:hover {
    background-color: #0056b3;
  }
`;

const ConfirmationButtons = styled.div`
  display: flex;
  justify-content: space-around;
  width: 80%;
  max-width: 400px;
  margin-top: 20px;
`;

const ConfirmationButton = styled.button<{ color: string }>`
  background-color: ${(props) => props.color};
  color: ${(props) => (props.color === 'lightgray' ? '#333' : 'white')};
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  border: ${(props) => (props.color === 'lightgray' ? 'none' : '1px solid transparent')};
  width: 200px;
  margin: 0 10px;

  &:hover {
    background-color: ${(props) => (props.color === 'lightgray' ? '#bbb' : '#0056b3')};
  }
`;

const CameraFeed = styled(Camera)`
  width: 100%;
  height: 100%;
`;

const TarjetaCirculacion: React.FC<{ onCapture: (photo: string) => void;  }> = ({ onCapture, }) => {
  const camera = useRef<CameraType>(null);
  const [image, setImage] = useState<string | null>(null);

  const handleTakePhoto = async () => {
    if (camera.current) {
      const photo = camera.current.takePhoto();
      setImage(photo as string);
    }
  };

  const handleConfirm = () => {
    if (image) {
      onCapture(image);
    }
  };

  const handleRetake = () => {
    setImage(null);
  };

  return (
    <>
      {/* Add the Header component with a back button */}
      <Header title="Tarjeta de Circulación"  />
      <Wrapper>
        <Title>Por favor toma una fotografía a tu Tarjeta de Circulación Por enfrente</Title>
        {image ? (
          <>
            <img src={image} alt="Tarjeta de Circulación" style={{ width: '80%', maxWidth: '400px', marginBottom: '20px' }} />
            <ConfirmationButtons>
              <ConfirmationButton color="lightgray" onClick={handleRetake}>Volver a Intentar</ConfirmationButton>
              <ConfirmationButton color="green" onClick={handleConfirm}>Sí</ConfirmationButton>
            </ConfirmationButtons>
          </>
        ) : (
          <>
            <CameraWrapper>
              <CameraFeed
                ref={camera}
                aspectRatio="cover"
                facingMode="environment"
                numberOfCamerasCallback={(i: number) => console.log(i)}
                errorMessages={{
                  noCameraAccessible:
                    'No camera device accessible. Please connect your camera or try a different browser.',
                  permissionDenied: 'Permission denied. Please refresh and give camera permission.',
                  switchCamera:
                    'It is not possible to switch camera to different one because there is only one video device accessible.',
                  canvas: 'Canvas is not supported.',
                }}
                videoReadyCallback={() => {
                  console.log('Video feed ready.');
                }}
              />
            </CameraWrapper>
            <TakePhotoButton onClick={handleTakePhoto}>Tomar fotografía</TakePhotoButton>
          </>
        )}
      </Wrapper>
    </>
  );
};

export default TarjetaCirculacion;
