import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useAppContext } from '../AppContext';
import qs from 'qs';

const Trabajador: React.FC = () => {
  const alldata = useAppContext();
  const [queue, setQueue] = useState<any[]>([]);
  const navigate = useNavigate();
 
  
const fetchQueue = async () => {
  try {
    const getCurrentDate = () => {
      const today = new Date();
      const yyyy = today.getFullYear();
      const mm = String(today.getMonth() + 1).padStart(2, "0");
      const dd = String(today.getDate()).padStart(2, "0");
      return `${yyyy}-${mm}-${dd}`;
    };

    const currentDate = getCurrentDate();
    console.log('Current date:', currentDate);
    const filter = {
      offset: 0,
      limit: 100,
      skip: 0,
      where: { creationDate: currentDate },
      fields: {
        driverId: true,
        creationDate: true,
        apellidos: true,
        nombres: true,
        fechanacimiento: true,
        curp: true,
        telefono: true,
        correo: true,
        rfc: true,
        domicilio: true,
        colonia: true,
        codigoPostal: true,
        ciudad: true,
        estado: true,
        pais: true,
        numerolicencia: true,
        expedicionlicencia: true,
        expiracionlicencia: true,
        cobertura: true,
        numeroPoliza: true,
        polizaArchivo: true,
        pagado: true,
        placa: true,
        serie: true,
        origen: true,
        linea: true,
        modelo: true,
        marca: true,
        version: true,
        tarjetaCirculacionPhoto: true,
        ineEnfrentePhoto: true,
        ineAtrasPhoto: true,
        cAmis: true
      }
    };

    // Convert the filter object to a query string
    const queryString = qs.stringify({ filter }, { encode: true, arrayFormat: 'brackets' });
    const response = await axios.get(`https://www.gruporomeroseguros.com/api/drivers?${queryString}`);
    console.log('API response:', response.data);

    // Ensure response.data is an array before setting the queue
    if (Array.isArray(response.data)) {
      setQueue(response.data);
    } else {
      console.error('Expected an array but got:', response.data);
    }
  } catch (error) {
    console.error("Error fetching queue:", error);
  }
};

  useEffect(() => {
      fetchQueue();
      const interval = setInterval(fetchQueue, 30000);
      return () => clearInterval(interval);
  }, []);

  return (
    <>
      {queue.map((client, index) => (
        <div key={index} className="client-card">
          <h3>{client.nombres} {client.apellidos}</h3>
          <button onClick={() => {
            console.log("Selected client:", client);
            alldata.setPlateNumber(client.placa);
            alldata.setScrapedData({
              serie: client.serie,
              origen: client.origen,
              marca: client.marca,
              linea: client.linea,
              version: client.version,
              modelo: client.modelo,
            });
            alldata.setParsedData({
              apellidos: client.apellidos,
              nombres: client.nombres,
              domicilio: client.domicilio,
              colonia: client.colonia,
              codigoPostal: client.codigoPostal,
            });
            alldata.setInputInfo({
              telefono: client.telefono,
              correo: client.correo,
              rfc: client.rfc,
            });
            const currentDate = new Date();
            const formattedDate = currentDate.toISOString().split('T')[0];
            axios.get(`https://www.gruporomeroseguros.com/photo/${formattedDate}/${client.tarjetaCirculacionPhoto}`).then(
              (response) => { 
                alldata.setCirculacionPhoto(response.data); 
              }).finally(() => {
                axios.get(`https://www.gruporomeroseguros.com/photo/${formattedDate}/${client.ineEnfrentePhoto}`).then(
                  (response) => { 
                    alldata.setIneEnfrentePhoto(response.data); 
                  }).finally(() => {
                    axios.get(`https://www.gruporomeroseguros.com/photo/${formattedDate}/${client.ineAtrasPhoto}`).then(
                      (response) => { 
                        alldata.setIneAtrasPhoto(response.data); 
                      }).finally(() => {
                            navigate(`/TablaFila/`);        
                      });
                  });
              }); 
          }}>Select</button>
        </div>
      ))}
    </>
  );
};

export default Trabajador;
