import React from 'react';
import { Link } from 'react-router-dom';
import { useNavigate  } from 'react-router-dom';

const ComingSoon: React.FC = () => {  
  const navigate = useNavigate();

  return (
    <div className="container">
      <nav>
        <div className="nav-container">
          <div className="nav-links">
            <ul>
                <li><Link to="/">Inicio</Link></li>
                <li><Link to="/FrontScreen/" >Comprar Seguro</Link></li>
                <li><Link to="/contacto">Contacto</Link></li>
                <button onClick={
                  () => {
                    localStorage.setItem('role', 'Trabajador');
                    navigate('/Trabajador');
                  }
                }>
                  Trabajadores
                </button>
            </ul>
          </div>
        </div>
      </nav>

      <main>
        <div className="content">
          <img 
            src="/logo-company.png" 
            alt="Grupo Asesor Logo" 
            className="logo"
          />
          <h1>Estamos trabajando para darte un mejor servicio</h1>
          <div className="divider"></div>
        </div>
      </main>
      
      <footer>
        <div className="footer-links">
            <Link to="/">Inicio</Link>
            <Link to="/FrontScreen/">Comprar Seguro</Link>
            <Link to="/contacto">Contacto</Link>
        </div>
      </footer>

      <style>{`
        .container {
          min-height: 100vh;
          display: flex;
          flex-direction: column;
          font-family: Arial, sans-serif;
          background-color: #f9fafb;
          margin: 0;
          padding: 0;
        }

        /* Navigation Styles */
        nav {
          background-color: white;
          box-shadow: 0 2px 4px rgba(0,0,0,0.1);
        }

        .nav-container {
          max-width: 1200px;
          margin: 0 auto;
          padding: 0 1rem;
        }

        .nav-links {
          display: flex;
          justify-content: center;
          padding: 1rem;
        }

        .nav-links ul {
          display: flex;
          gap: 2rem;
          list-style: none;
          padding: 0;
          margin: 0;
        }

        .nav-links a {
          color: #374151;
          text-decoration: none;
          padding: 1rem 0.5rem;
          transition: color 0.3s;
        }

        .nav-links a:hover {
          color: #10B981;
        }

        /* Main Content Styles */
        main {
          flex-grow: 1;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          padding: 4rem 1rem;
        }

        .content {
          text-align: center;
        }

        .logo {
          width: 200px;
          height: 200px;
          border-radius: 50%;
          margin-bottom: 2rem;
          box-shadow: 0 4px 6px rgba(0,0,0,0.1);
          object-fit: cover;
        }

        h1 {
          font-size: 2.25rem;
          font-weight: bold;
          color: #1F2937;
          margin-bottom: 1rem;
        }

        .divider {
          width: 4rem;
          height: 0.25rem;
          background-color: #10B981;
          margin: 2rem auto;
        }

        /* Footer Styles */
        footer {
          background-color: white;
          box-shadow: 0 -2px 4px rgba(0,0,0,0.1);
          margin-top: auto;
          padding: 1rem;
        }

        .footer-links {
          display: flex;
          justify-content: center;
          gap: 1.75rem;
        }

        .footer-links a {
          color: #374151;
          text-decoration: none;
          transition: color 0.3s;
        }

        .footer-links a:hover {
          color: #10B981;
        }

        /* Responsive Styles */
        @media (max-width: 640px) {
          h1 {
            font-size: 1.75rem;
          }
          
          .nav-links ul {
            gap: 1rem;
          }
        }
      `}</style>
    </div>
  );
};

export default ComingSoon;