import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useNavigate  } from 'react-router-dom';

const HeaderWrapper = styled.div`
  width: 100%;
  height: 60px;
  background-color: #007bff;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const BackButton = styled.button`
  position: absolute;
  left: 20px;
  background-color: transparent;
  border: none;
  color: white;
  font-size: 16px;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;

const Title = styled.h1`
  color: white;
  font-size: 18px;
  margin: 0;
`;
interface HeaderProps {
  title: string;
}

const Header: React.FC<HeaderProps> = ({ title }) => {
  const navigate = useNavigate();
  function onBack(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void {
    navigate(-1);
  }
  useEffect(() => {

  }, []);
  return (
    <HeaderWrapper>
      <BackButton onClick={onBack}>Atrás</BackButton>
      <Title>{title}</Title>
    </HeaderWrapper>
  );
};

export default Header;
