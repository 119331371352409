import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { useNavigate } from "react-router-dom";


const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const StartScreen = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  min-height: 100vh;
  background-color: #f0f0f0;
  padding: 20px;
  box-sizing: border-box;
`;

const LoadingContainer = styled.div`
  width: 100%;
  max-width: 400px;
  padding: 0 20px;
`;

const LoadingText = styled.div`
  text-align: center;
  margin-bottom: 10px;
  color: #666;
`;

const LoadingBarContainer = styled.div`
  width: 100%;
  height: 10px;
  background-color: #ddd;
  border-radius: 5px;
  overflow: hidden;
`;

const LoadingBar = styled.div<{ progress: number }>`
  width: ${props => props.progress}%;
  height: 100%;
  background-color: #4299e1;
  transition: width 0.3s ease;
`;

const Logo = styled.img`
  max-width: 200px;
  width: 80%;
  height: auto;
  margin-bottom: 2rem;

  @media (min-width: 768px) {
    width: 50%;
    margin-bottom: 3rem;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  max-width: 400px;
  margin-bottom: 3rem;
  padding: 0 20px;
`;

const Button = styled.button`
  width: 100%;
  padding: 12px 24px;
  border-radius: 8px;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  border: none;
  color: white;
  text-align: center;

  &:hover {
    transform: scale(1.05);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }

  &:active {
    transform: scale(0.95);
  }

  @media (min-width: 768px) {
    font-size: 20px;
  }
`;

const GreenButton = styled(Button)`
  background-color: #10b981;
  &:hover {
    background-color: #059669;
  }
`;

const BlueButton = styled(Button)`
  background-color: #38bdf8;
  &:hover {
    background-color: #0ea5e9;
  }
`;

const AdContainer = styled.div`
  width: 100%;
  max-width: 960px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 0 20px;
`;

const AdBanner = styled.div`
  background-color: white;
  border-radius: 8px;
  padding: 1rem;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #e5e7eb;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  animation: ${fadeIn} 0.5s ease-in;

  &:hover {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }

  @media (min-width: 768px) {
    height: 96px;
  }
`;

const AdText = styled.span`
  color: #666;
  font-size: 14px;

  @media (min-width: 768px) {
    font-size: 16px;
  }
`;

const FrontScreen: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [progress, setProgress] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        const newProgress = Math.min(oldProgress + 10, 100);
        if (newProgress === 100) {
          setLoading(false);
          clearInterval(timer);
        }
        return newProgress;
      });
    }, 200);

    return () => clearInterval(timer);
  }, []);

  return (
    <StartScreen>
      {loading ? (
        <LoadingContainer>
          <LoadingText>Cargando...</LoadingText>
          <LoadingBarContainer>
            <LoadingBar progress={progress} />
          </LoadingBarContainer>
        </LoadingContainer>
      ) : (
        <>
          <Logo src="/logo-company.png" alt="Company Logo" />
          <ButtonContainer>
            <GreenButton onClick={() => navigate('/EleccionInicial')}>
              Seguro para Placas
            </GreenButton>
            <BlueButton onClick={() => navigate('/ubicaciones')}>
              Ubicaciones
            </BlueButton>
            <BlueButton onClick={() => navigate('/contacto')}>
              Contacto
            </BlueButton>
          </ButtonContainer>
          <AdContainer>
            <AdBanner>
              <AdText>Espacio Publicitario 1</AdText>
            </AdBanner>
            <AdBanner>
              <AdText>Espacio Publicitario 2</AdText>
            </AdBanner>
            <AdBanner>
              <AdText>Espacio Publicitario 3</AdText>
            </AdBanner>
          </AdContainer>
        </>
      )}
    </StartScreen>
  );
};

export default FrontScreen;
