import React from 'react';
import styled from 'styled-components';
import Header from '../components/Header'; // Import the Header component
import { useNavigate } from "react-router-dom";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: calc(100vh - 60px); /* Adjust for header height */
  background-color: #f0f0f0;
  padding: 20px;
  box-sizing: border-box; /* Ensure padding is included in the total width and height */
`;

const Title = styled.h1`
  font-size: 24px;
  margin-bottom: 20px;
  color: #333;
`;

const OptionTitle = styled.h2`
  color: #007bff;
  font-size: 18px;
  margin: 10px;
`;

const Button = styled.button`
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  margin: 5px;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
`;

const SmallText = styled.p`
  font-size: 12px;
  color: #666;
  font-weight: bold;
  margin-top: -5px;
  margin-bottom: 10px;
`;

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
`;


const Coberturas: React.FC = () => {
  const navigate = useNavigate();
  return (
    <>
      {/* Add the Header component with a back button */}
      <Header title="Coberturas" />
      <Wrapper>
        <Title>Elige tu cobertura</Title>

        <OptionTitle>Solo México</OptionTitle>
        <ButtonGroup>
          <Button onClick={() => navigate('/InputPlacas/')}>Obligatoria</Button>
          <SmallText>$948.00 anual</SmallText>
          <Button onClick={() => navigate('/InputPlacas/')}>Con Asistencia Vial</Button>
          <SmallText>$1,130.00 anual</SmallText>
          <Button onClick={() => navigate('/InputPlacas/')}>Con Asistencia Legal</Button>
          <SmallText>$1,210.00 anual</SmallText>
        </ButtonGroup>

        <OptionTitle>Mex-USA</OptionTitle>
        <ButtonGroup>
          <Button onClick={() => navigate('/InputPlacas/')}>Obligatoria</Button>
          <SmallText>$1,210.00 anual</SmallText>
          <Button onClick={() => navigate('/InputPlacas/')}>Con Asistencia Vial</Button>
          <SmallText>$1,380.00 anual</SmallText>
          <Button onClick={() => navigate('/InputPlacas/')}>Con Asistencia Legal</Button>
          <SmallText>$1,460.00 anual</SmallText>
        </ButtonGroup>
      </Wrapper>
    </>
  );
};

export default Coberturas;
