import React from 'react';
import styled from 'styled-components';
import Header from '../components/Header'; 

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: calc(100vh - 60px); /* Adjust for header height */
  background-color: #f0f0f0;
  padding: 20px;
  box-sizing: border-box;
`;

const Title = styled.h1`
  font-size: 24px;
  margin-bottom: 20px;
  color: #333;
  text-align: center;
`;

const DataContainer = styled.div`
  width: 80%;
  max-width: 600px;
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
`;

const DataItem = styled.p`
  font-size: 16px;
  color: #333;
  margin: 5px 0;
`;

const ButtonRow = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const Button = styled.button`
  background-color: ${(props) => (props.color ? props.color : '#007bff')};
  color: ${(props) => (props.color === '#ccc' ? '#333' : 'white')};
  padding: 10px 20px;
  margin: 10px;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  border: ${(props) => (props.color === '#ccc' ? 'none' : '1px solid transparent')};
  width: 200px;

  &:hover {
    background-color: ${(props) => (props.color === '#ccc' ? '#bbb' : props.color)};
  }
`;

const YesButton = styled(Button)`
  background-color: green;
  font-weight: bold;

  &:hover {
    background-color: darkgreen;
  }
`;

interface ConfirmarPlacasProps {
  data: {
    placa: string;
    serie: string;
    origen: string;
    marca: string;
    linea: string;
    version: string;
    modelo: string;
  };
  onConfirm: (confirmed: boolean) => void;
}

const ConfirmarPlacas: React.FC<ConfirmarPlacasProps> = ({ data, onConfirm }) => {
  return (
    <>
      {/* Add the Header component with a back button */}
      <Header title="Confirmar Placas"  />
      <Wrapper>
        <Title>¿La información es correcta?</Title>
        <DataContainer>
          <DataItem><strong>Placa:</strong> {data.placa}</DataItem>
          <DataItem><strong>Serie:</strong> {data.serie}</DataItem>
          <DataItem><strong>Origen:</strong> {data.origen}</DataItem>
          <DataItem><strong>Marca:</strong> {data.marca}</DataItem>
          <DataItem><strong>Línea:</strong> {data.linea}</DataItem>
          <DataItem><strong>Versión:</strong> {data.version}</DataItem>
          <DataItem><strong>Modelo:</strong> {data.modelo}</DataItem>
        </DataContainer>
        <ButtonRow>
          <Button color="#ccc" onClick={() => onConfirm(false)}>No</Button>
          <YesButton onClick={() => onConfirm(true)}>Sí</YesButton>
        </ButtonRow>
      </Wrapper>
    </>
  );
};

export default ConfirmarPlacas;
