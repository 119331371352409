import React from "react";
import axios from "axios";
import { useAppContext } from "../AppContext";

const UploadAndWait: React.FC = () => {
  const alldata = useAppContext();
  const bdate = "18/09/1996";
  const [day, month, year] = bdate.split("/").map((part) => parseInt(part, 10));
  const date = new Date(year, month - 1, day);
  function getFormattedTimestamp(date: Date): string {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }
  function getFormatteddate(date: Date): string {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }
  const customTimestamp = getFormattedTimestamp(date);
  const customDate = getFormatteddate( new Date());
  return (
    <div style={styles.container}>
      <>
        <p style={styles.text}>
          presione boton para pedir turno de atencion...
        </p>
        <button
          onClick={async () => {
            // Send a POST request
            console.log("alldata.circulacionPhotoNombre", alldata.circulacionPhotoNombre);
            console.log("alldata.ineEnfrentePhotoNombre", alldata.ineEnfrentePhotoNombre);
            console.log("alldata.ineAtrasPhotoNombre", alldata.ineAtrasPhotoNombre);
            const response = await axios({
                method: "post",
                url: "https://www.gruporomeroseguros.com/api/drivers/",
                data: {
                  apellidos: alldata.parsedData?.apellidos,
                  creationDate: customDate,
                  nombres: alldata.parsedData?.nombres,
                  fechanacimiento: new Date(customTimestamp).toISOString(), // Ensure the correct date-time format
                  curp: alldata.parsedData?.curp,
                  telefono: alldata.inputInfo?.telefono,
                  correo: alldata.inputInfo?.correo,
                  rfc: alldata.inputInfo?.rfc,
                  domicilio: alldata.parsedData?.domicilio,
                  colonia: alldata.parsedData?.colonia,
                  codigoPostal: alldata.parsedData?.codigoPostal,
                  ciudad: "tijuana",
                  estado: "bajacalifornia",
                  pais: "mexico",
                  numerolicencia: "vera1234",
                  expedicionlicencia: new Date(customTimestamp).toISOString(), // Ensure the correct date-time format
                  expiracionlicencia: new Date(customTimestamp).toISOString(), // Ensure the correct date-time format
                  cobertura: "amplia",
                  numeroPoliza: "123",
                  polizaArchivo: "string.pdf",
                  pagado: 0,
                  placa: alldata.plateNumber,
                  serie: alldata.scrapedData?.serie,
                  origen: alldata.scrapedData?.origen,
                  linea: alldata.scrapedData?.linea,
                  modelo: alldata.scrapedData?.modelo,
                  marca: alldata.scrapedData?.marca,
                  version: alldata.scrapedData?.version,
                  tarjetaCirculacionPhoto: alldata.circulacionPhotoNombre, // Ensure this property is not missing
                  ineEnfrentePhoto: alldata.ineEnfrentePhotoNombre,
                  ineAtrasPhoto: alldata.ineAtrasPhotoNombre, // Ensure this property is not missing
                  cAmis: 0,
                },
              });
              
            console.log("API response:", response.data);
          }}
        >
          Pedir Turno
        </button>
      </>
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    flexDirection: "column" as "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    backgroundColor: "#fff",
  },
  spinner: {
    width: "50px",
    height: "50px",
    border: "5px solid #f3f3f3",
    borderTop: "5px solid #0000ff",
    borderRadius: "50%",
    animation: "spin 1s linear infinite",
  },
  text: {
    marginTop: "20px",
    fontSize: "18px",
    color: "#333",
  },
};

export default UploadAndWait;
